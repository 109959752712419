import React from "react";
import {
    Page,
    View,
    Text,
    Font,
    Document,
    Image,
    Svg,
    Circle,
    Path,
} from "@react-pdf/renderer";

// Import styles
import {styles} from "./styles";

// Import fonts
import font from "../../assets/fonts/Montserrat/Montserrat-Light.ttf";
import boldFont from "../../assets/fonts/Montserrat/Montserrat-Medium.ttf";
import bolderFont from "../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf";

// Import images
import Cabecalho from "../../assets/cabecalho/cabecalho.png";
import Logo from "../../assets/logo/logo.png";
import Experiencia from "../../assets/icons/experiencia.png";
import Formacao from "../../assets/icons/formacao.png";
import Certificado from "../../assets/icons/certificado.png";
import Curso from "../../assets/icons/curso.png";
import Check from "../../assets/check/check.png";

// Register fonts
Font.register({
    family: "Montserrat",
    fonts: [
        {src: font},
        {src: boldFont, fontStyle: "bold"},
        {src: bolderFont, fontStyle: "bolder"},
    ],
});

// Remove word hyphenation
Font.registerHyphenationCallback((word) => [word]);

const experienciasMap = (userData, experiencias) => {
    return experiencias.map((exp, index) => {
        if (!exp.emptyLine && !exp.repeatTitle) {
            return (
                <View style={styles.Exp} key={`exp ${index}`}>
                    <Text style={styles.text}>
                        <Text style={styles.experience}>{exp.nome} </Text>
                        {exp.inicio} {exp.fim && "- " + exp.fim}
                    </Text>
                    <Text style={styles.text}>
                        {exp.cargo && (
                            <>
                                <Text style={styles.cargo}>Position: </Text>
                                {exp.cargo}
                            </>
                        )}
                    </Text>
                    <Text style={styles.description}>{exp.descricao}</Text>
                </View>
            );
        } else if (exp.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < exp.emptyLines; i++) {
                lines.push(
                    <View key={`exp ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (exp.repeatTitle === true) {
            return (
                <View style={styles.Exps} key={`exp ${index}`}>
                    <Text style={styles.title}>Experience</Text>
                    <Image style={styles.iconExpRepeat} src={Experiencia}/>
                </View>
            );
        }
        return <></>;
    });
};
const consideracoesMap = (userData, consideracoes) => {
    return consideracoes.map((cons, index) => {
        if (!cons.emptyLine && !cons.repeatTitle) {
            return (
                <View style={styles.Cons} key={`cons ${index}`}>
                    <Text style={styles.text}>
                        {/* <Text style={styles.experience}>{cons.nome} </Text> */}
                        {/*{cons.inicio} {cons.fim && "- " + cons.fim}*/}
                    </Text>
                    {/*<Text style={styles.text}>*/}
                    {/*  {cons.cargo && (*/}
                    {/*      <>*/}
                    {/*        <Text style={styles.cargo}>Cargo: </Text>*/}
                    {/*        {cons.cargo}*/}
                    {/*      </>*/}
                    {/*  )}*/}
                    {/*</Text>*/}
                    <Text style={styles.description}>{cons.descricao}</Text>
                </View>
            );
        } else if (cons.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < cons.emptyLines; i++) {
                lines.push(
                    <View key={`cons ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (cons.repeatTitle === true) {
            return (
                <View style={styles.Exps} key={`cons ${index}`}>
                    <Text style={styles.title}>Experiência</Text>
                    <Image style={styles.iconExpRepeat} src={Experiencia}/>
                </View>
            );
        }
        return <></>;
    });
};

const formacoesMap = (formacoes) => {
    return formacoes.map((form, index) => {
        if (!form.emptyLine && !form.repeatTitle) {
            return (
                <View style={styles.Exp} key={`form ${index}`}>
                    <Text style={styles.text}>
                        <Text style={styles.experience}>{form.nome} </Text>
                        {form.instituicao}
                    </Text>

                    <Text style={styles.description}>
                        {form.tipo} {form.conclusao && `• ${form.conclusao}`}
                    </Text>
                </View>
            );
        } else if (form.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < form.emptyLines; i++) {
                lines.push(
                    <View key={`form ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (form.repeatTitle === true) {
            return (
                <View key={`form ${index}`}>
                    <Text style={styles.title}>Education</Text>
                    <Image style={styles.iconFormRepeat} src={Formacao}/>
                </View>
            );
        }
        return <></>;
    });
};
const cursosMap = (cursos) => {
    return cursos.map((cur, index) => {
        if (!cur.emptyLine && !cur.repeatTitle) {
            return (
                <View style={styles.Exp} key={`cur ${index}`}>
                    <Text style={styles.text}>
                        <Text style={styles.experience}>{cur.nome} </Text>
                    </Text>

                    <Text style={styles.description}>
                        {cur.instituicao}
                        {cur.conclusao && ` • ${cur.conclusao}`}
                    </Text>
                </View>
            );
        } else if (cur.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < cur.emptyLines; i++) {
                lines.push(
                    <View key={`cur ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (cur.repeatTitle === true) {
            return (
                <View key={`cur ${index}`}>
                    <Text style={styles.title}>Additional Courses</Text>
                    <Image style={styles.iconCurRepeat} src={Curso}/>
                </View>
            );
        }
        return <></>;
    });
};
const certificadosMap = (certificados) => {
    return certificados.map((cert, index) => {
        if (!cert.emptyLine && !cert.repeatTitle) {
            return (
                <View style={styles.Exp} key={`cert ${index}`}>
                    <Text style={styles.text}>
                        <Text style={styles.experience}>{cert.nome} </Text>
                    </Text>

                    <Text style={styles.description}>
                        {cert.instituicao}
                        {cert.conclusao && ` • ${cert.conclusao}`}
                    </Text>
                </View>
            );
        } else if (cert.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < cert.emptyLines; i++) {
                lines.push(
                    <View key={`cert ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (cert.repeatTitle === true) {
            return (
                <View key={`cert ${index}`}>
                    <Text style={styles.title}>Certificates</Text>
                    <Image style={styles.iconCertRepeat} src={Certificado}/>
                </View>
            );
        }
        return <></>;
    });
};

const converterNivel = (nivel) => {
    let circles = [];
    let nivelTruncado = Math.trunc(nivel);
    let nivelRestante = Math.trunc(6 - nivel);
    let i = 0;
    while (i < nivelTruncado) {
        i++;
        circles.push(1);
    }
    if (nivel % 1 === 0.5) {
        circles.push(0.5);
    }
    while (nivelRestante > 0) {
        circles.push(0);
        nivelRestante--;
    }
    return circles;
};
const interessesMap = (interesses) => {
    return interesses.map((int, index) => {
        if (!int.emptyLine && !int.repeatTitle) {
            if (int.level === "") {
                return (
                    <View style={styles.semNivelContainer} key={`int ${index}`}>
                        <View style={styles.semNivel}>
                            <Text style={styles.cargoRight} fixed>
                                {int.nome}
                            </Text>
                            <Image style={styles.check} src={Check} fixed/>
                        </View>
                        <View>
                            <Text style={styles.complemento}>{int.complemento} </Text>
                        </View>
                    </View>
                );
            }
            let circles = converterNivel(int.level);
            return (
                <View style={styles.interesse} key={`int ${index}`}>
                    <Text style={styles.cargoRight}>{int.nome} </Text>
                    {int.complemento !== "" && (
                        <Text style={styles.complemento}>{int.complemento} </Text>
                    )}
                    <View style={styles.circles}>
                        {circles.map((circle, index) => {
                            switch (circle) {
                                case 1:
                                    return (
                                        <Svg style={styles.circle} key={`circ ${index}`}>
                                            <Circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                fill="#3855d9"
                                                stroke="#3855d9"
                                                stroke-width="3"
                                            />
                                        </Svg>
                                    );
                                case 0.5:
                                    return (
                                        <View style={styles.halfCircleSVG} key={`circ ${index}`}>
                                            <Svg style={styles.circle}>
                                                <Circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.5"
                                                    fill="white"
                                                    stroke="#3855d9"
                                                    stroke-width="3"
                                                />
                                                <Path d="M0,5 a1,1 0 0,0 10,0" fill="#3855d9"/>
                                            </Svg>
                                        </View>
                                    );
                                case 0:
                                    return (
                                        <Svg style={styles.circle} key={`circ ${index}`}>
                                            <Circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                fill="white"
                                                stroke="#3855d9"
                                                stroke-width="3"
                                            />
                                        </Svg>
                                    );
                                default:
                                    return <></>;
                            }
                        })}
                    </View>
                </View>
            );
        } else if (int.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < int.emptyLines; i++) {
                lines.push(
                    <View key={`int ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (int.repeatTitle === true) {
            return (
                <View key={`int ${index}`}>
                    {/* <Text style={styles.titleRight}>Principais Interesses</Text> */}
                    <View style={styles.titleRight}>
                        <Text>Main Skills</Text>
                    </View>
                </View>
            );
        }
        return <></>;
    });
};
const habilidadesMap = (habilidades) => {
    return habilidades.map((int, index) => {
        if (!int.emptyLine && !int.repeatTitle) {
            if (int.level === "") {
                return (
                    <View style={styles.semNivelContainer} key={`int ${index}`}>
                        <View style={styles.semNivel}>
                            <Text style={styles.cargoRight} fixed>
                                {int.nome}
                            </Text>
                            <Image style={styles.check} src={Check} fixed/>
                        </View>
                        <View>
                            <Text style={styles.complemento}>{int.complemento} </Text>
                        </View>
                    </View>
                );
            }
            let circles = converterNivel(int.level);
            return (
                <View style={styles.interesse} key={`int ${index}`}>
                    <Text style={styles.cargoRight}>{int.nome} </Text>
                    {int.complemento !== "" && (
                        <Text style={styles.complemento}>{int.complemento} </Text>
                    )}
                    <View style={styles.circles}>
                        {circles.map((circle, index) => {
                            switch (circle) {
                                case 1:
                                    return (
                                        <Svg style={styles.circle} key={`circle ${index}`}>
                                            <Circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                fill="#3855d9"
                                                stroke="#3855d9"
                                                stroke-width="3"
                                            />
                                        </Svg>
                                    );
                                case 0.5:
                                    return (
                                        <View style={styles.halfCircleSVG} key={`circle ${index}`}>
                                            <Svg style={styles.circle}>
                                                <Circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.5"
                                                    fill="white"
                                                    stroke="#3855d9"
                                                    stroke-width="3"
                                                />
                                                <Path d="M0,5 a1,1 0 0,0 10,0" fill="#3855d9"/>
                                            </Svg>
                                        </View>
                                    );
                                case 0:
                                    return (
                                        <Svg style={styles.circle} key={`circle ${index}`}>
                                            <Circle
                                                cx="5"
                                                cy="5"
                                                r="4"
                                                fill="white"
                                                stroke="#3855d9"
                                                stroke-width="3"
                                            />
                                        </Svg>
                                    );
                                default:
                                    return <></>;
                            }
                        })}
                    </View>
                </View>
            );
        } else if (int.emptyLine === true) {
            let lines = [];
            for (let i = 0; i < int.emptyLines; i++) {
                lines.push(
                    <View key={`hab ${index}`}>
                        <Text>{"    "}</Text>
                    </View>
                );
            }
            return lines;
        } else if (int.repeatTitle === true) {
            return (
                <View key={`int ${index}`}>
                    <View style={styles.titleRight}>
                        <Text>Complementary</Text>
                        <Text>Skills</Text>
                    </View>
                </View>
            );
        }
        return <></>;
    });
};

// Create Document Component
export const MyDocumentInEnglish = ({userData}) => {
    const userDataJSON = JSON.stringify(userData);
    const text = new Buffer(userDataJSON, "utf-8").toString("base64")
    return (
        <Document subject={text}>
            <Page style={styles.body}>
                <View style={styles.header} fixed>
                    <Image style={styles.headerImg} src={Cabecalho}/>

                    <Text style={styles.headerFuncao}>
                        {Object.keys(userData).length > 0 &&
                        Object.keys(userData.candidato).length > 0
                            ? userData.candidato.titulo
                            : "Position"}
                    </Text>
                    <Text style={styles.headerNome}>
                        {Object.keys(userData).length > 0 &&
                        Object.keys(userData.candidato).length > 0
                            ? `${userData.candidato.nome}`
                            : "Name"}
                    </Text>
                </View>
                <View style={styles.line} fixed></View>
                <View style={styles.bodyCenter}>
                    <View style={styles.bodyLeft}>
                        {Object.keys(userData).length > 0 &&
                            userData.consideracoes &&
                            userData.consideracoes.length > 0 && (
                                <>
                                    <Text style={styles.title}>Premiersoft Considerations</Text>
                                    <View style={styles.Exps}>
                                        <Image style={styles.iconExp} src={Experiencia}/>
                                        {consideracoesMap(userData, userData.consideracoes)}
                                    </View>
                                </>
                            )}
                        {Object.keys(userData).length > 0 &&
                            userData.experiencias.length > 0 && (
                                <>
                                    <Text style={styles.title}>Experience</Text>
                                    <View style={styles.Exps}>
                                        <Image style={styles.iconExp} src={Experiencia}/>
                                        {experienciasMap(userData, userData.experiencias)}
                                    </View>
                                </>
                            )}
                        {Object.keys(userData).length > 0 && userData.formacoes.length > 0 && (
                            <>
                                <Text style={styles.title}>Education</Text>
                                <View style={styles.Exps}>
                                    <Image style={styles.iconForm} src={Formacao}/>
                                    {formacoesMap(userData.formacoes)}
                                </View>
                            </>
                        )}

                        {Object.keys(userData).length > 0 && userData.cursos.length > 0 && (
                            <>
                                <Text style={styles.title}>Additional Courses</Text>
                                <View style={styles.Exps}>
                                    <Image style={styles.iconCur} src={Curso}/>
                                    {cursosMap(userData.cursos)}
                                </View>
                            </>
                        )}

                        {Object.keys(userData).length > 0 &&
                            userData.certificados.length > 0 && (
                                <>
                                    <Text style={styles.title}>Certificates</Text>
                                    <View style={styles.Exps}>
                                        <Image style={styles.iconCert} src={Certificado}/>
                                        {certificadosMap(userData.certificados)}
                                    </View>
                                </>
                            )}
                    </View>

                    <View style={styles.bodyRight}>
                        {Object.keys(userData).length > 0 &&
                            userData.interesses.length > 0 && (
                                <>
                                    {/* <Text style={styles.titleRight}>Main Interesses</Text> */}
                                    <View style={styles.titleRight}>
                                        <Text>Main Skills</Text>
                                    </View>
                                    <View style={styles.Exps}>
                                        {interessesMap(userData.interesses)}
                                    </View>
                                </>
                            )}
                        {Object.keys(userData).length > 0 &&
                            userData.habilidades.length > 0 && (
                                <>
                                    <View style={styles.titleRight}>
                                        <Text>Complementary</Text>
                                        <Text>Skills</Text>
                                    </View>
                                    <View style={styles.Exps}>
                                        {habilidadesMap(userData.habilidades)}
                                    </View>
                                </>
                            )}
                    </View>
                </View>
                <Image style={styles.logo} src={Logo} fixed/>
                <Text style={styles.footerUm} fixed>
                    <Text style={styles.footerPremier}>PremierSoft Inc.</Text>
                </Text>
                <Text style={styles.footerDois} fixed>
                    Rua Itajai, 3377, Blumenau, Brazil
                </Text>
                <Text style={styles.footerRight} fixed>
                    *Restricted access document.
                </Text>
            </Page>
        </Document>
    );
};
